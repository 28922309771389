<template>
  <v-sheet class="sales" style="height: calc(100vh - 140px)">
    <v-row>
      <v-layout class="mx-2 overflow w-100 justify-start" style="max-width: 100%; overflow: auto">
        <!-- {{ selectedRows }} -->
        <!-- <v-col md="0" class="pl-0" min-width="245px" max-width="220px">
            <v-card class="" style="border: 0.5px solid #90caf9">
              <v-card-actions>
                <v-list-item class="grow px-0">
                  <v-list-item-content>
                    <v-list-item-title
                      class="custom-form-label me-2"
                      style="font-weight: 600; font-size: 16px"
                    >
                      MONTH</v-list-item-title
                    >
                  </v-list-item-content>
                  <div align="center" justify="end">
                    <span class="subheading">
                      <v-chip color="#0D47A1" style="font-size: 18px; color: white; font-weight: 600">
                        01 Monday</v-chip
                      >
                    </span>
                  </div>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </v-col> -->
        <v-col md="0" class="pl-0" min-width="245px" max-width="220px">
          <v-card class="" style="border: 0.5px solid #ef9a9a">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label"
                    style="font-weight: 600; font-size: 16px"
                  >
                    TOTAL POS</v-list-item-title
                  >
                </v-list-item-content>

                <div align="center" justify="end">
                  <span class="subheading">
                    <v-chip
                      color="#F44336"
                      style="font-size: 18px; color: white; font-weight: 600"
                      >{{ formatMoney(sales.sales) }}</v-chip
                    >
                  </span>
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col class="pl-0" min-width="245px" max-width="220px">
          <v-card class="" style="border: 0.5px solid #ab47bc">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label"
                    style="font-weight: 600; font-size: 16px"
                  >
                    TOTAL FEE</v-list-item-title
                  >
                </v-list-item-content>

                <div align="center" justify="end">
                  <span class="subheading">
                    <v-chip
                      color="#8E24AA"
                      style="font-size: 18px; color: white; font-weight: 600"
                      >{{ formatMoney(sales.bank_fee) }}</v-chip
                    >
                  </span>
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col class="pl-0" min-width="245px" max-width="220px">
          <v-card class="" style="border: 0.5px solid #81c784">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label"
                    style="font-weight: 600; font-size: 16px"
                  >
                    TOTAL ESTIMATED
                  </v-list-item-title>
                </v-list-item-content>

                <div align="center" justify="end">
                  <span class="subheading">
                    <v-chip
                      color="#1B5E20"
                      style="font-size: 18px; color: white; font-weight: 600"
                      >{{ formatMoney(sales.estimated_fee) }}</v-chip
                    >
                  </span>
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col class="pl-0" min-width="245px" max-width="220px">
          <v-card class="" style="border: 0.5px solid #00b0ff">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label"
                    style="font-weight: 600; font-size: 16px"
                  >
                    TOTAL RECEIVED
                  </v-list-item-title>
                </v-list-item-content>

                <div align="center" justify="end">
                  <span class="subheading">
                    <v-chip
                      color="#00B0FF"
                      style="font-size: 18px; color: white; font-weight: 600"
                      >{{ formatMoney(sales.banking) }}</v-chip
                    >
                  </span>
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col class="pl-0" min-width="245px" max-width="220px">
          <v-card class="" style="border: 0.5px solid #aed581">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content class="me-2">
                  <v-list-item-title
                    class="custom-form-label"
                    style="font-weight: 600; font-size: 16px"
                  >
                    TOTAL DISCREPANCY
                  </v-list-item-title>
                </v-list-item-content>

                <div align="center" justify="end">
                  <span class="subheading">
                    <v-chip
                      color="#558B2F"
                      style="font-size: 18px; color: white; font-weight: 600"
                      >{{ formatMoney(sales.discrepancy) }}</v-chip
                    >
                  </span>
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
        <!-- <v-col class="pl-0" min-width="245px" max-width="220px">
            <v-card class="" style="border: 0.5px solid #c2185b">
              <v-card-actions>
                <v-list-item class="grow px-0">
                  <v-list-item-content>
                    <v-list-item-title
                      class="custom-form-label"
                      style="font-weight: 600; font-size: 16px"
                    >
                      TOTAL SALES
                    </v-list-item-title>
                  </v-list-item-content>
                  <div align="center" justify="end">
                    <span class="subheading"
                      ><v-chip color="#C2185B" style="font-size: 18px; color: white; font-weight: 600"
                        >{{formatMoney(totalsales)}}</v-chip
                      ></span
                    >
                  </div>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </v-col> -->
      </v-layout>
    </v-row>
    <v-form
      ref="customerForm"
      v-model.trim="formValid"
      lazy-validation
      v-on:submit.stop.prevent="saveRecord"
    >
      <v-card-text class="px-0">
        <v-row class="mt-5">
          <v-col class="my-auto">
            <h4>
              Statement Of - <b>{{ formatedate(sales.date) }}</b>
            </h4>
          </v-col>

          <v-col class="justify-content-end">
            <v-flex class="d-flex justify-content-end">
              <v-btn
                v-if="type == 1"
                :disabled="pageLoading || !formValid"
                :loading="pageLoading"
                class="white--text mr-2 ml-2"
                depressed
                color="blue darken-4"
                tile
                v-on:click="saveRecord()"
              >
                Save
              </v-btn>
              <v-btn
                v-if="type == 1"
                class="ml-2"
                style="font-weight: 700; color: black"
                :disabled="pageLoading"
                depressed
                tile
                v-on:click="cancel()"
              >
                Cancel
              </v-btn>
              <v-btn
                v-if="type == 0"
                class="ml-2"
                color="blue darken-4"
                style="font-weight: 700; color: white"
                :disabled="pageLoading"
                depressed
                tile
                v-on:click="addLineItems(sales_detail.length)"
              >
                Edit
              </v-btn>

              <v-btn
                class="ml-2"
                v-if="type == 0"
                style="font-weight: 700; color: black"
                :disabled="pageLoading"
                depressed
                tile
                v-on:click="goBack()"
              >
                Back
              </v-btn>
            </v-flex>
          </v-col>
        </v-row>
      </v-card-text>

      <v-row>
        <v-simple-table style="width: 100%" fixed-header class="bt-table table_height_summary">
          <template v-slot:default>
            <thead>
              <tr style="background-color: #f5f5f5">
                <!-- <th style="background-color: #f5f5f5">
                           Date
                        </th> -->
                <th style="background-color: #f5f5f5">Reference Number</th>
                <th style="background-color: #f5f5f5">Amount</th>
                <th style="background-color: #f5f5f5">Action</th>
              </tr>
            </thead>
            <tbody v-if="!pageLoading" v-click-outside="clickOutside">
              <template v-if="sales_detail && sales_detail.length">
                <tr
                  v-for="(row, rowindex) in sales_detail"
                  :key="rowindex"
                  :class="rowindex % 2 === 0 && 'blue lighten-5'"
                  class="salessummary-listing-tr"
                >
                  <td style="display: none">
                    <v-chip small color="#4CAF50" style="color: white; font-weight: 600">
                      {{ formatedate(row.date) }}
                    </v-chip>
                  </td>
                  <td class="cursor-pointer">
                    <template>
                      <template v-if="type == 1">
                        <div
                          @click="onFocus($event, rowindex)"
                          :class="['font-weight-medium', 'd-flex align-center']"
                          style="font-size: 1.2em; height: 100%"
                        >
                          <TextInput
                            :id="'reference_' + rowindex"
                            :ref="'reference_' + rowindex"
                            v-if="rowindex === activeRowIndex || row.isEditable"
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            v-model="row.reference"
                            customClass="px-0"
                          ></TextInput>
                          <template v-else>{{ row.reference }}</template>
                        </div>
                      </template>
                      <template v-else>
                        <div
                          :class="['font-weight-medium', 'd-flex align-center']"
                          style="font-size: 1.2em; height: 100%"
                        >
                          <TextInput
                            :id="'reference_' + rowindex"
                            :ref="'reference_' + rowindex"
                            v-if="type == 1"
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            v-model="row.reference"
                            customClass="px-0"
                          ></TextInput>
                          <template v-else-if="row.reference">{{ row.reference }}</template>
                          <template v-else>No Reference No</template>
                        </div>
                      </template>
                    </template>
                  </td>
                  <td class="cursor-pointer">
                    <template v-if="type == 1">
                      <div
                        @click="onFocus($event, rowindex)"
                        :class="['font-weight-medium', 'd-flex align-center']"
                        style="font-size: 1.2em; height: 100%"
                      >
                        <QuantityInput
                          :id="'amount_' + rowindex"
                          v-if="rowindex === activeRowIndex || row.isEditable"
                          hide-details
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          v-model="row.amount"
                          customClass="px-0"
                        ></QuantityInput>

                        <template v-else>{{ formatMoney(row.amount) }}</template>
                      </div>
                    </template>
                    <template v-else>
                      <div
                        :class="['font-weight-medium', 'd-flex align-center']"
                        style="font-size: 1.2em; height: 100%"
                      >
                        <QuantityInput
                          :id="'amount_' + rowindex"
                          v-if="type == 1"
                          hide-details
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          v-model="row.amount"
                          customClass="px-0"
                        ></QuantityInput>

                        <template v-else>{{ formatMoney(row.amount) }}</template>
                      </div>
                    </template>
                  </td>
                  <td class="cursor-pointer">
                    <v-tooltip top content-class="custom-top-tooltip" v-if="type == 1">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          depressed
                          fab
                          dark
                          x-small
                          @click="addLineItems(rowindex)"
                          class="mx-2"
                          color="green"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>

                    <template v-if="row.id">
                      <v-tooltip
                        v-if="sales_detail && sales_detail.length"
                        top
                        content-class="custom-top-tooltip"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            depressed
                            fab
                            dark
                            x-small
                            class="me-2"
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                            @click.stop.prevent="deleteConfirm(row.id)"
                          >
                            <v-icon>mdi-delete-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </template>
                    <template v-else>
                      <v-tooltip
                        v-if="sales_detail && sales_detail.length > 1"
                        top
                        content-class="custom-top-tooltip"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            depressed
                            fab
                            dark
                            x-small
                            class="me-2"
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteLineItems(rowindex)"
                          >
                            <v-icon>mdi-delete-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </template>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="3">
                    <p class="m-0 text-center">
                      <img
                        width="30"
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image mr-4"
                      />
                      Uhh... There are no online banking sales at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <tr v-for="idr in 3" :key="idr">
                <td v-for="idk in 3" :key="idk">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-row>
    </v-form>

    <OrderTemplate
      v-model="order"
      :customer-id="sales_detailId"
      :dialog="orderDialog"
      v-on:close="orderDialog = false"
      v-on:save="saveOrder()"
      v-on:collect:voucher="getAvailableVouchers"
      v-on:claim:voucher="getCollectedVouchers"
    >
    </OrderTemplate>
    <CollectTemplate
      :dialog="collectDialog"
      :order-id="cOrderId"
      :order-code="cOrderCode"
      :vouchers="cVouchers"
      v-on:close="vCollected()"
      v-on:collected="vCollected()"
    ></CollectTemplate>
    <RedeemTemplate
      :dialog="redeemDialog"
      :order-id="rOrderId"
      :order-code="rOrderCode"
      :vouchers="rVouchers"
      v-on:close="vRedeemed()"
      v-on:redeemed="vRedeemed()"
    ></RedeemTemplate>
    <DeleteTemplate
      type="Online Banking Sales"
      :delete-dialog="deleteDialog"
      :delete-endpoint="deleteEndpoint"
      v-on:close="deleteDialog = false"
      v-on:delete:success="getSalesSummary()"
    >
    </DeleteTemplate>
    <SearchTemplate
      :dialog="allCollectedDialog"
      v-on:close="allCollectedDialog = false"
    ></SearchTemplate>
    <Dialog dense :dialog="whatsappPromotional" v-if="whatsappPromotional">
      <template v-slot:title> Whatsapp Promotional </template>
      <template v-slot:body>
        <div class="mx-6" fluid>
          <v-row class="mx-6">
            <v-col md="2">
              <label for="item-file-upload" class="btx-label mt-2 required">Template</label>
            </v-col>
            <v-col md="8">
              <SelectInput
                hide-details
                hideTopMargin
                :loading="pageLoading"
                :disabled="pageLoading"
                item-text="text"
                dense
                item-value="value"
                :items.sync="templateList"
                v-model="whatsapp.template"
              ></SelectInput>
            </v-col>
          </v-row>
          <v-row class="mx-6">
            <v-col md="2">
              <label for="item-file-upload" class="btx-label mt-2 required">File</label>
            </v-col>
            <v-col md="8">
              <v-file-input
                v-model="whatsapp.files"
                ref="fileInput"
                hide-details
                id="item-file-upload"
                label="Upload Images"
                outlined
                dense
              ></v-file-input>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="pageLoading"
          :loading="pageLoading"
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          @click="whatsappPromotional = false"
        >
          Send
        </v-btn>
        <v-btn :disabled="pageLoading" depressed tile v-on:click="whatsappPromotional = false">
          Cancel
        </v-btn>
      </template>
    </Dialog>
    <Dialog :dialog="openDialog" card-text-class="px-4 py-0" :dialogWidth="800">
      <template v-slot:title>Banking<v-spacer></v-spacer></template>
      <template v-slot:body>
        <v-container>
          <v-row>
            <v-col md="12" class="py-1">
              <!-- <div class="custom-header-blue-text text-h5 text-center mb-2">Record Payment</div> -->
              <table class="table table-sm mb-0" width="100%">
                <thead class="table-light">
                  <tr>
                    <th>#</th>
                    <th class="text-center">Date</th>

                    <th class="text-end">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, index) in item"
                    :key="index"
                    :class="index % 2 === 0 && 'blue lighten-5'"
                    class="salessummary-listing-tr"
                  >
                    <td class="text-h6">
                      {{ index + 1 }}
                    </td>
                    <td align="center" class="text-capitalize text-h6">
                      {{ formatedate(row.date) }}
                    </td>
                    <td align="end" class="text-h6">{{ formatMoney(row.cash) }}</td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
          <template> </template>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn class="ml-2" depressed tile v-on:click="close"> Cancel </v-btn>
      </template>
    </Dialog>
    <Banking :dialog="Banking" :detail="data" @close="Banking = false"></Banking>
    <Percentage :dialog="Percentage" :detail="data" @close="Percentage = false"></Percentage>
  </v-sheet>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import {
  GET_ONLINE_LIST_BANKING,
  CREATE_ONLINE_BANKING,
  GET_TEMPLATE_LIST,
} from "@/core/lib/customer.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import OrderTemplate from "@/view/components/OrderTemplate";
import CollectTemplate from "@/view/components/CollectVoucherTemplate";
import RedeemTemplate from "@/view/components/RedeemVoucherTemplate";
import SearchTemplate from "@/view/components/SearchAllVouchers";
// import DateRangePicker from "@/view/components/DateRangePicker";
// import ValueTemplate from "@/view/components/ValueTemplate";
import PosMixin from "@/core/mixins/pos.mixin";
import Banking from "./Banking";
import Percentage from "./Percentage";
import TextInput from "@/view/components/TextInput";
import QuantityInput from "@/view/components/QuantityInput";
import { SET_MESSAGE } from "@/core/services/store/common.module";

import { SearchEventBus } from "@/core/lib/search.lib";

import {
  SET_SEARCH_TITLE,
  SET_SEARCH_BAR,
  BEFORE_DESTROY,
} from "@/core/services/store/listing.module";

import { mapGetters } from "vuex";
import { format } from "date-fns";
import { GET_AVAILABLE_VOUCHERS, GET_COLLECTED_VOUCHERS } from "@/core/lib/common.lib";
import SelectInput from "@/view/components/SelectInput.vue";
import moment from "moment-timezone";
import { QUERY } from "@/core/services/store/request.module";
import { cloneDeep } from "lodash";
//import AutoCompleteInput from "@/view/components/AutoCompleteInput";

export default {
  mixins: [PosMixin],

  name: "sales-update",
  title: "Details Sales Summry",
  data() {
    return {
      text: "Also called a company description, your business description summarizes what your company does, its purpose, and what makes it unique. It's a critical component of your business plan and immediately follows your executive summary",
      dialog: false,
      data: [],
      formValid: true,
      sales: {},
      defaultColDefs: [],
      selectedRows: [],
      activeRowIndex: null,
      estimated_fee: 0,
      banking: 0,
      customThead: [],
      Qr_sales: 0,
      fee: 0,
      detail: [],
      Banking: false,
      Percentage: false,
      openDialog: false,
      templateList: [{ text: "First", value: "first" }],
      template: "first",
      discrepancy: 0,
      whatsapp: {
        files: [],
        template: "first",
      },
      summaryTableHeading: [
        { title: "CHECK", status: true },
        { title: "ACTION", status: true },
        { title: "DATE", status: true },
        { title: "TOTAL SALES", status: true },
        { title: "CASH SALES", status: true },
        { title: "VISA", status: true },
        { title: "DEBIT", status: true },
        { title: "MASTERCARD", status: true },
        { title: "ONLINE BANKING", status: true },
        { title: "TOUCH AND GO", status: true },
        { title: "QR PAY", status: true },
        { title: "GRAB PAY", status: true },
        { title: "BOOST", status: true },
        { title: "GRAB FOOD", status: true },
        { title: "FOOD PANDA", status: true },
        { title: "FOC", status: true },
        { title: "VOUCHER CLAIM", status: true },
        { title: "VOUCHER SOLD", status: true },
        { title: "VOUCHER AWARD", status: true },
        { title: "BANKING", status: true },
      ],
      months: [
        {
          name: "January",
          value: 1,
        },
        {
          name: "February",
          value: 2,
        },
        {
          name: "March",
          value: 3,
        },
        {
          name: "April",
          value: 4,
        },
        {
          name: "May",
          value: 5,
        },
        {
          name: "June",
          value: 6,
        },
        {
          name: "July",
          value: 7,
        },
        {
          name: "August",
          value: 8,
        },
        {
          name: "September",
          value: 9,
        },
        {
          name: "October",
          value: 10,
        },
        {
          name: "November",
          value: 11,
        },
        {
          name: "December",
          value: 12,
        },
      ],
      detailSummaryData: [],
      filterType: "all",
      type: 0,
      filterDateRange: null,
      speedDial: [],
      whatsappPromotional: false,
      sales_detail: [],
      item: [],
      visa_card: 0,
      debit_card: 0,
      totalsales: 0,
      currentPage: 1,
      online_banking: 0,
      pageLimit: 1,
      showColumn: false,
      showingFrom: 0,
      showingTo: 0,
      master_card: 0,
      totalRows: 0,
      pageLoading: true,
      deleteText: null,
      deleteEndpoint: null,
      deleteDialog: false,
      sales_detailId: null,
      orderDialog: false,
      rOrderId: 0,
      rOrderCode: null,
      rLoading: false,
      rVouchers: [],
      redeemDialog: false,
      cOrderId: 0,
      cOrderCode: null,
      cLoading: false,
      cVouchers: [],
      collectDialog: false,
      allCollectedDialog: false,
      dragStartIndex: null,
      dragOverIndex: null,
      order: {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      },
    };
  },
  watch: {
    summaryTableHeading: {
      handler: function (newval) {
        console.log(newval);
      },
      deep: true,
    },
    currentPage() {
      this.getSalesSummary();
    },
    listingSearch() {
      this.getSalesSummary();
    },
  },
  methods: {
    async saveRecord() {
      if (!this.$refs.customerForm.validate()) {
        return false;
      }
      this.sales_date = this.sales.date;
      this.sales_id = this.sales.id;
      this.arrays = this.sales_detail.filter((array) => array.amount > 0);
      this.arrays_id = this.sales_detail.filter((array) => array.amount <= 0 && array.id > 0);
      if (this.arrays_id.length) {
        this.$store.commit(SET_ERROR, [{ model: true, message: "The amount  field not valid" }]);
        return false;
      }
      if (!this.arrays.length) {
        this.$store.commit(SET_ERROR, [{ model: true, message: "The amount  field is required" }]);
        return false;
      }
      try {
        const customer = await CREATE_ONLINE_BANKING(this.arrays, this.sales_date, this.sales_id);
        console.log(customer);
        this.$router.replace({
          name: "onlinesummary",
          query: { t: new Date().getTime() },
        });
        this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Online  Created Successfully." },
        ]);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
        this.getSalesSummary();
      }
    },

    cancel() {
      this.type = 0;
      this.sales_detail = [];
      this.getSalesSummary();
    },
    clickOutside() {
      console.log({ activeRowIndex: this.activeRowIndex });
      this.activeRowIndex = null;
    },
    onBlur(event, rowIndex) {
      this.activeRowIndex = rowIndex;
    },
    onFocus(event, rowIndex) {
      this.activeRowIndex = rowIndex;

      this.$nextTick(() => {
        const ourForm = event.target;
        const ourInp = ourForm.querySelector("input");
        if (ourInp) ourInp.focus() || ourInp.select();
      });

      this.isOutside = false;
      const element = event.target;
      //console.log(element.nodeName);
      const _line_items = this.sales_detail.map((row) => {
        return { ...row, isEditable: false };
      });
      this.sales_detail = _line_items;
      this.sales_detail[rowIndex].isEditable = true;
      this.$nextTick(() => {
        const inputarea = element.querySelector("input");
        if (inputarea) {
          inputarea.focus();
          inputarea.select();
        }
      });
      if (element.nodeName == "INPUT") {
        element.select();
      }
      if (this.sales_detail.length == rowIndex + 1) {
        this.addLineItems(rowIndex);
      }
    },

    deleteLineItems(index) {
      this.sales_detail.splice(index, 1);
    },
    addLineItems(rowindex) {
      this.type = 1; // Set the type variable to 1
      let preData = cloneDeep(this.sales_detail);
      preData.splice(rowindex + 1, 0, {
        isEditable: true,
        reference: null,
        amount: null,
      });
      this.sales_detail = preData;
    },

    // showColumn(){
    //   this.showColumn = true;
    // },
    reset() {
      this.filterDateRange = null;
      this.getSalesSummary();
    },
    searchSales() {
      this.getSalesSummary();
    },
    close() {
      this.openDialog = false;
    },
    openBank(bank) {
      this.openDialog = true;
      console.log(bank, "bank");
      this.$store
        .dispatch(QUERY, {
          url: `find-banking/${bank}`,
        })
        .then((data) => {
          this.item = data.rows;
          console.log(data);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {});
    },
    getPayments(selectedRows) {
      this.data = selectedRows;
      console.log(selectedRows, "selectedAll");
    },
    formatedate(param) {
      return moment(param).format("DD/MM/YYYY");
    },
    getColValue(val) {
      if (val.includes(" ")) {
        return val.split(" ").join("_").toLowerCase();
      } else {
        return val.toLowerCase();
      }
    },
    dragstart(index) {
      this.dragStartIndex = index;
    },
    dragover(index) {
      this.dragOverIndex = index;
    },
    dragEnd() {
      const dragStartElement = this.summaryTableHeading[this.dragStartIndex];
      this.summaryTableHeading.splice(this.dragStartIndex, 1);
      this.summaryTableHeading.splice(this.dragOverIndex, 0, dragStartElement);
    },
    drastart(index) {
      this.dragging_element_index = index;
    },
    downloadTableDataOfSummary() {
      const summaryContent = this.generateCSV();
      const blobContent = new Blob([summaryContent], { type: "text/csv" });
      const downLoadlink = document.createElement("a");
      downLoadlink.href = window.URL.createObjectURL(blobContent);
      downLoadlink.download = "restaurant.csv";
      downLoadlink.click();
    },
    generateCSV() {
      const tableHeaders = [
        "DATE",
        "GRAB PAY",
        "CASH SALES",
        "GRAB FOOD",
        "TOUCH AND GO",
        "FOOD PANDA",
        "DEBIT",
        "BOOST",
        "ONLINE BANK IN",
        "MAY BANK",
        "FOC",
        "SHOPPEPAY",
        "TOTAL SALES",
        "VOUCHER",
        "CITY LEDGER",
      ];
      const rows = this.detailSummaryData;
      console.log(rows.date.value, "rows");
      // (
      //   rows.date.value,
      //   rows.grab_pay.value,
      //   rows.cash_sales.value
      // );
      // .map((item) => [
      //   item.date.value,
      //   item.grab_pay.value,
      //   item.cash_sales.value,
      //   item.grab_food.value,
      //   item.touch_and_go.value,
      //   item.food_panda.value,
      //   item.debit.value,
      //   item.boost.value,
      //   item.online_bank_in.value,
      //   item.may_bank.value,
      //   item.foc.value,
      //   item.shoppepay.value,
      //   item.total_sales.value,
      //   item.voucher.value,
      //   item.city_ledger.value,
      // ]);
      //...rows.map((row) => row.join(","))
      const csvRows = [tableHeaders.join(","), rows];
      return csvRows.join("\n");
    },

    filterTypeChange() {
      this.filterDateRange = null;
    },
    saveOrder() {
      this.sales_detailId = null;
      this.order = {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      };
    },
    addOrder({ id }) {
      this.sales_detailId = id;
      this.orderDialog = true;
    },
    vCollected() {
      this.cOrderId = 0;
      this.cOrderCode = null;
      this.cVouchers = [];
      this.collectDialog = false;
      this.saveOrder();
    },
    vRedeemed() {
      this.rOrderId = 0;
      this.rOrderCode = null;
      this.rVouchers = [];
      this.redeemDialog = false;
      this.saveOrder();
    },
    async getAvailableVouchers(orderId, barcode) {
      try {
        this.cOrderId = orderId;
        this.cOrderCode = barcode;
        this.cLoading = true;
        this.cVouchers = await GET_AVAILABLE_VOUCHERS(orderId);
        this.collectDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.cLoading = false;
      }
    },
    async getCollectedVouchers(orderId, barcode) {
      try {
        this.rOrderId = orderId || 0;
        this.rOrderCode = barcode || null;
        this.rLoading = true;
        this.rVouchers = await GET_COLLECTED_VOUCHERS(this.sales_detailId);
        this.redeemDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.rLoading = false;
      }
    },
    routeToUpdate(id) {
      this.$router.push({
        name: "sales-update",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    routeToDetail(id) {
      this.$router.push({
        name: "qrsales-final",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    deleteConfirm(id) {
      this.deleteEndpoint = `delete-onlinesale-bank/${id}`;
      this.deleteDialog = true;
      this.getSalesSummary();
    },
    async getSalesSummary() {
      try {
        let form = {
          current_page: this.currentPage,
          search: this.listingSearch,
          filter_type: this.filterType,
          month: this.$route.params.id,
          year: this.$route.query.year,
        };
        if (this.filterDateRange) {
          const dateRange = {
            start_date: this.filterDateRange[0],
            end_date: this.filterDateRange[1],
          };
          form = { ...form, ...dateRange };
        }
        this.pageLoading = true;
        const {
          rows,
          totalPages,
          showingFrom,
          showingTo,
          totalRows,
          fee,
          Qr_sales,
          estimated_fee,
          banking,
          discrepancy,
          online_banking,
        } = await GET_ONLINE_LIST_BANKING(form);
        this.sales = rows;
        this.sales_detail = rows?.onlinepay;
        this.pageLimit = totalPages;
        this.showingFrom = showingFrom;
        this.showingTo = showingTo;
        this.totalRows = totalRows;
        this.fee = fee;
        this.Qr_sales = Qr_sales;
        this.estimated_fee = estimated_fee;
        this.banking = banking;
        this.discrepancy = discrepancy;
        this.online_banking = online_banking;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    resetSearch() {
      this.$router
        .replace({
          query: {
            t: new Date().getTime(),
          },
        })
        .then(() => {});
    },
    async getTemplateListing() {
      try {
        this.pageLoading = true;
        console.log("getTemplateListing");
        const data = await GET_TEMPLATE_LIST();
        console.log("data", data);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
  },
  async mounted() {
    this.getSalesSummary();
    const { name, params } = this.$route;
    if (name === "online-final") {
      this.detailSummaryData = params.row;
    } else {
      this.goBack();
    }
    this.$store.dispatch(SET_SEARCH_BAR, true);
    this.$store.dispatch(SET_SEARCH_TITLE, "ONLINE BANKING SALES");
  },

  components: {
    DeleteTemplate,
    OrderTemplate,
    CollectTemplate,
    RedeemTemplate,
    SearchTemplate,
    // DateRangePicker,
    TextInput,
    SelectInput,
    Dialog,
    QuantityInput,
    //AutoCompleteInput,
    // ValueTemplate,
    Banking,
    Percentage,
  },
  beforeDestroy() {
    this.$store.dispatch(BEFORE_DESTROY);
    SearchEventBus.$emit("search-template", false);
  },
  computed: {
    ...mapGetters(["listingSearch"]),
    selectedAll: {
      set(param) {
        this.selectedRows = [];
        if (param && this.sales_detail.length) {
          for (let i = this.sales_detail.length - 1; i >= 0; i--) {
            if (this.sales_detail[i].banking == null) {
              this.selectedRows.push(this.sales_detail[i]);
              this.data = this.selectedRows;
            }
          }
        }
      },
      get() {
        let totalItems = this.sales_detail.length;

        return totalItems > 0 ? this.selectedRows.length === totalItems : false;
      },
    },
  },
};
</script>

<style>
.table_height_summary .v-data-table__wrapper {
  height: calc(100vh - 340px);
}

::-webkit-scrollbar {
  height: 10px;
}

.detail-salessummary-listing-tr:nth-child(even) {
  background-color: #e3f2fd !important;
  border-color: #e3f2fd !important;
}

.detail-salessummary-listing-tr:nth-child(odd) {
  background-color: #e3f2fd !important;
  border-color: #e3f2fd !important;
}

.detail-salessummary-listing-tr:nth-child(even):hover {
  background: #fff !important;
  box-shadow: 0px 0px 20px 2px #666666 !important;
  -webkit-box-shadow: 0px 0px 20px 2px #666666 !important;
  -moz-box-shadow: 0px 0px 20px 2px #666666 !important;
}

.detail-salessummary-listing-tr:nth-child(odd):hover {
  box-shadow: 0px 0px 20px 2px #666666 !important;
  -webkit-box-shadow: 0px 0px 20px 2px #666666 !important;
  -moz-box-shadow: 0px 0px 20px 2px #666666 !important;
}

.table.table-sm td {
  padding: 0.6rem !important;
  vertical-align: middle;
}

.table.table-sm th {
  background-color: #ede8e8 !important;
}
</style>
