<template>
  <v-sheet>
    <Dialog :dialog="Banking" card-text-class="px-4 py-0" :dialogWidth="500">
      <template v-slot:title>Edit % <v-spacer></v-spacer> </template>

      <template v-slot:body>
        <v-container>
          <template>
            <v-layout class="bg_grey pt-0 pb-0 px-0 my-0">
              <v-row>
                <v-form
                  ref="customerForm"
                  v-model.trim="formValid"
                  lazy-validation
                  v-on:submit.stop.prevent="saveRecord"
                >
                  <template>
                    <v-layout class="bg_grey pt-0 pb-0 px-0 my-0">
                      <v-col class="" md="16">
                        <table>
                          <tr>
                            <td width="100%">
                              <label for="payment-number" class="field-label required"
                                >Percentage %</label
                              >
                              <QuantityInput
                                hide-details
                                :loading="pageLoading"
                                id="payment-made"
                                placeholder="Amount"
                                style="width: 350px !important"
                                v-model="value"
                                class="mb-1 ml-2"
                                :rules="[vrules.required(value, 'value')]"
                                :class="{ required: !value }"
                                @keyup="totalamount(filterLists, index)"
                                prefixText="RM"
                              >
                              </QuantityInput>
                            </td>
                          </tr>
                        </table>
                      </v-col>
                    </v-layout>
                  </template>
                </v-form>
              </v-row>
            </v-layout>
          </template>
        </v-container>
      </template>

      <template v-slot:action>
        <v-btn
          :disabled="pageLoading || !formValid"
          :loading="pageLoading"
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          v-on:click="saveRecord()"
        >
          Save
        </v-btn>
        <v-btn
          class="ml-2"
          :disabled="excelLoading"
          depressed
          tile
          v-on:click="$emit('close', false)"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </v-sheet>
</template>

<script>
import { UPLOAD } from "@/core/services/store/request.module";

import Dialog from "@/view/components/Dialog";
//import FileUpload from "@/view/components/FileUpload";
import { formatMoney } from "accounting-js";
import { UPDATE_PERCENTAGE } from "@/core/lib/customer.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";

import QuantityInput from "@/view/components/QuantityInput";
import moment from "moment-timezone";

//   import DatePicker from "@/view/components/DatePicker";
//   import TextInput from "@/view/components/TextInput";
//   import TextAreaInput from "@/view/components/TextAreaInput";
// import SelectInput from "@/view/components/SelectInput";
// import PurchaseDocument from "@/view/components/PurchaseDocument";
export default {
  name: "item-dialog",
  title: "Dialog Item",

  data() {
    return {
      record: [],
      formValid: true,
      searchDate: null,
      product: [],

      value: null,

      amount: 0,
      filterLists: [
        {
          value: null,

          //     files: [
          //   {
          //     file: null,
          //     name: null,
          //     suffix: null,
          //     size: null,
          //     id: null,
          //   },
          // ],
        },
      ],
      className: null,
      excelLoading: null,
      Banking: false,
      paymentThroughList: [
        { text: "Petty Cash", value: 1 },
        { text: "Undeposited Founds", value: 2 },
      ],
      paymentModeList: [
        { text: "Cash", value: 1 },
        { text: "Credit Card Citi Bank", value: 2 },
        { text: "Credit Card C I M B", value: 3 },
        { text: "Credit Card Public Bank", value: 4 },
        { text: "Boss", value: 4 },
      ],
      paymentRecordData: {
        remark: null,
        attachments: [],
        date: null,
        total_cash: 0,
      },
      paymentRecord: [
        {
          po_number: "PO0001",
          paymentDate: "25/10/2023",
          venderName: "business thrust techsoft pvt ltd",
          amount: 309.88,
        },
      ],
    };
  },
  mounted() {},
  methods: {
    async updateFile(index, file) {
      const _this = this;

      try {
        _this.primaryLoader = true;
        let formData = new FormData();
        formData.append("files[0]", file, file.name);
        _this.$store
          .dispatch(UPLOAD, { url: "file-manager", data: formData })
          .then((data) => {
            this.filterLists[index].files = data[0]?.file?.url;
            console.log(data[0].file.url);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            _this.primaryLoader = false;
          });
      } catch (error) {
        console.log(error);
      }

      // if (file && file.name) {
      //   this.files[index].base64 = await this.convert_base_64(file);
      //   console.log(this.files[index].base64)
      //   this.files[index].name = file.name.split(".").slice(0, -1).join(".");
      //   this.files[index].suffix = `${file.name.split(".").pop()}`;
      //   this.files[index].size = file?.size;
      //   this.files[index].type = file?.type;
      //   this.files[index].id = file?.id;

      // } else {
      //   this.files[index].base64 = null;
      //   this.files[index].name = null;
      //   this.files[index].suffix = null;
      //   this.files[index].size = file?.size;
      //   this.files[index].type = file?.type;
      //   this.files[index].id = file?.id;
      // }
    },
    selectPv() {
      this.paymentRecordData.date = this.searchDate;
    },
    totalamount(item, index) {
      this.product = item;

      let sum = 0;

      this.product?.forEach((element) => {
        sum = sum + Number(element.amount);
      });
      this.amount = sum;
      if (sum > this.paymentRecordData.total_cash) {
        // this.filterLists[index].amount = 0;
        // this.amount=0;
      }
      console.log(index);
      console.log(sum);
      console.log(this.paymentRecordData.total_cash, "this.paymentRecordData.total_cash");
      // this.discount_total = Number(sum);
      //  this.getDiscount();
    },
    addFilter() {
      this.filterLists.push({
        refrence: null,
        amount: null,
      });
    },
    removeFilter(index) {
      this.filterLists.splice(index, 1);
    },
    async saveRecord() {
      if (!this.$refs.customerForm.validate()) {
        return false;
      }

      this.paymentRecordData = this.value;

      try {
        const customer = await UPDATE_PERCENTAGE(this.paymentRecordData);
        console.log(customer);
        this.$router.replace({
          name: "salessummary",
          query: { t: new Date().getTime() },
        });
        this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Percentage  Updated Successfully." },
        ]);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    formatedate(param) {
      return moment(param).format("DD/MM/YYYY");
    },
    getTotals() {
      this.product = this.detail;

      let sum = 0;

      this.product?.forEach((element) => {
        sum = sum + Number(element.sales);
      });

      this.paymentRecordData.total_cash = Number(sum);

      return this.paymentRecordData.total_cash;
    },

    formatMoney(money) {
      return formatMoney(money, {
        symbol: "RM",
        decimal: ".",
        thousand: ",",
        precision: 2,
        format: "%s%v",
      });
    },
  },
  watch: {
    dialog(params) {
      this.Banking = params;
    },
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    detail: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  components: {
    Dialog,
    //FileUpload,
    //   TextInput,
    //   DatePicker,
    //   TextAreaInput,
    // SelectInput,
    // PurchaseDocument,
    QuantityInput,
  },
};
</script>

<style scoped>
.table.table-sm td {
  padding: 0.6rem !important;
  vertical-align: middle;
}

.table.table-sm th {
  background-color: #ede8e8 !important;
}
</style>
